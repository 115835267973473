import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import dayjs from 'dayjs'
import store from '@/app/ui/store'
import {
  Programs,
  Program,
  ProgramHistory,
  ProgramHistories,
  Trigger,
  ProgrammaticParcelPoinAndSaldo,
  ProgrammaticParcelPoinAndSaldos,
  ProgrammaticParcelPoinAndSaldoPagination,
  ProgrammaticParcelPoinAndSaldoInviteeInviter,
  ProgrammaticParcelPoinAndSaldoInviteeInviterPagination,
  ProgrammaticParcelPoinAndSaldoInviteeInviterData,
} from '@/domain/entities/Program'
import { PointPurpose } from '@/domain/entities/PointPurpose'
import { Pagination } from '@/domain/entities/Pagination'
import { ProgramaticPointPresenter } from '@/app/ui/presenters/ProgramaticPointPresenter'
import {
  Utils,
  EventBus,
  EventBusConstants,
  LocalStorage,
} from '@/app/infrastructures/misc'
import {
  AllProgrammaticPointApproval,
  CreateProgramaticPointRequest,
  DeleteProgramaticPointRequest,
} from '@/data/payload/api/ProgramaticPointRequest'
import {
  TriggerType,
  UserType,
} from '@/app/infrastructures/misc/Constants/programaticPoint'
import { FormProgrammaticPoint } from '@/data/infrastructures/misc/interfaces/programmaticPoint'

export interface ProgramaticPointState {
  isLoading: boolean
  isDownloading: boolean
  isLoadingPurposeOptions: boolean
  isLoadingTriggers: boolean
  isLoadingRoute: boolean
  isLoadingPaymentMethods: boolean
  isLoadingMembershipLevel: boolean
  programData: Program[]
  paginationData: Pagination
  programPayload: CreateProgramaticPointRequest
  programDetail: Program
  pointPurposes: PointPurpose[]
  triggerData: Record<string, any>[]
  historyData: ProgramHistory[]
  paginationHistory: Pagination
  paymentMethods: string[]
  membershipLevel: { label: string, value: number }[]
}

@Module({ namespaced: true, store, name: 'programatic-point', dynamic: true })
class ProgramaticPointController extends VuexModule
  implements ProgramaticPointState {
  private presenter: ProgramaticPointPresenter = container.resolve(
    ProgramaticPointPresenter
  )
  public isLoading = false
  public isLoadingInviteeInviter = false
  public isLoadingSubmitApproval = false
  public isDownloading = false
  public isLoadingPurposeOptions = false
  public isLoadingTriggers = false
  public isLoadingRoute = false
  public isLoadingPaymentMethods = false
  public isLoadingMembershipLevel = false
  public isParcelPointProgram = false
  public successSubmitApproval = false
  public programData = [new Program()]
  public paginationData = new Pagination()
  public programPayload = new CreateProgramaticPointRequest()
  public programDetail = new Program()
  public pointPurposes = [new PointPurpose()]
  public triggerData = [{ label: '', value: '' }]
  public historyData = [new ProgramHistory()]
  public paginationHistory = new Pagination()
  public paymentMethods: string[] = []
  public membershipLevel: { label: string, value: number }[] = []
  public statusCancelledProgram = ''
  public paginationProgrammaticParcelPoinAndSaldo: ProgrammaticParcelPoinAndSaldoPagination = new ProgrammaticParcelPoinAndSaldoPagination()
  public programmaticParcelPoinAndSaldoData: ProgrammaticParcelPoinAndSaldo[] = []
  public paginationProgrammaticPointInviteeInviter: ProgrammaticParcelPoinAndSaldoInviteeInviterPagination = new ProgrammaticParcelPoinAndSaldoInviteeInviterPagination()
  public programmaticPointInviteeInviterData: ProgrammaticParcelPoinAndSaldoInviteeInviter[] = []

  @Action({ rawError: true })
  public getProgramUsage(form: {
    id: string
    params: Record<string, unknown>
  }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .get(form.id)
      .then(res => {
        const dateCreated = Utils.formatDate(res.createdAt || '')
        const isParcelPointTrigger = [
          TriggerType.REFERRAL_CODE_USED,
          TriggerType.REFERRAL_INVITEE,
        ].includes(<TriggerType>res.triggerBy?.toUpperCase() || '')

        const isParcelPointUserType = [UserType.LOGIN].includes(
          <UserType>res.target?.toUpperCase() || ''
        )

        this.setProgram(res)
        if (
          typeof res.purpose === 'string' &&
          res.purpose.trim() === '' &&
          isParcelPointTrigger &&
          isParcelPointUserType
        ) {
          this.setParcelPointProgram(true)
          return this.presenter.getHistoryParcelPoint(form.id, formattedParams)
        }
        this.setParcelPointProgram(false)
        return this.presenter.getHistory(form.id, formattedParams)
      })
      .then(resHistory => {
        this.setProgramHistory(resHistory)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProgramHistory(form: {
    id: string
    params: Record<string, unknown>
  }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getHistory(form.id, formattedParams)
      .then(res => {
        this.setProgramHistory(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProgramHistoryV2(form: {
    id: string
    params: Record<string, unknown>
  }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getHistoryParcelPoint(form.id, formattedParams)
      .then(res => {
        this.setProgramHistory(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFile(form: {
    id: string
    params: Record<string, unknown>
  }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportHistory(form.id, formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFileNeedApprovalAllProgrammatic(form: {
    params: Record<string, unknown>
  }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportNeedApprovalAllProgrammatic(formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedProgrammaticData(form: {
    params: Record<string, unknown>
  }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportProgrammaticData(formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedProgrammaticDataDetail(form: {
    params: Record<string, unknown>
  }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportProgrammaticDataDetail(formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFileV2(form: {
    id: string
    params: Record<string, unknown>
  }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportHistoryV2(form.id, formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getPointPurposes() {
    this.setPurposeLoading(true)

    this.presenter
      .getPointPurposes(new Map())
      .then(res => {
        this.setPointPurposes(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Point Purpose Error',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setPurposeLoading(false)
      })
  }

  @Action({ rawError: true })
  public getTriggers() {
    this.setTriggerLoading(true)

    this.presenter
      .getTriggers()
      .then(res => {
        this.setTriggerData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Trigger List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setTriggerLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProgramDetail(id: string) {
    this.setLoading(true)

    this.presenter
      .get(id)
      .then(res => {
        this.setProgram(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Error',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProgramaticList(params: Record<string, any>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setPrograms(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Error',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async sendProgram(form: FormProgrammaticPoint) {
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )
    let selectedUsers: string | undefined = undefined

    this.setLoading(true)

    if (form.userTarget?.value === UserType.SELECTED) {
      try {
        selectedUsers = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onerror = reject
          reader.onload = e => {
            try {
              import('xlsx').then(XLSX => {
                const data = new Uint8Array(
                  <Iterable<number>>e.target?.result
                )
                const workbook = XLSX.read(data, { type: 'array' })
                const sheetName = workbook.SheetNames[0] // Assume it is always in the first sheet
                const worksheet = workbook.Sheets[sheetName]
                const selectedUsers = XLSX.utils
                  .sheet_to_json(worksheet)
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .map((it: any) => {
                    if (it instanceof Object && 'customer_id' in it) {
                      return it.customer_id
                    } else {
                      reject(
                        'Invalid Excel data format: header column customer_id should be exist'
                      )
                    }
                  })
                  .join(',')
                resolve(selectedUsers)
              })
            } catch (error) {
              reject('Invalid Attachment: ' + error)
            }
          }
          reader.readAsArrayBuffer(form.fileUsers)
        })
      } catch (error) {
        this.setLoading(false)
        Vue.notify({
          title: 'Submitting Failed',
          text: 'Invalid Attachment: ' + error,
          type: 'error',
          duration: 8000,
        })

        return
      }
    }

    const payload = new CreateProgramaticPointRequest(
      form.programName,
      form.userTarget?.value,
      selectedUsers,
      form.triggerBy?.value,
      form.routes.map(item => <number>item.value),
      form.triggerBy?.value === TriggerType.ON_SCHEDULE
        ? dayjs(
            `${form.scheduleDate} ${form.scheduleTime}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format()
        : undefined,
      form.invoiceSource.join(','),
      form.paymentMethod.join(','),
      form.minimumTransaction,
      form.purpose.join(','),
      form.isPercentage,
      // form.pointAmount,
      form.percentOfTransaction,
      form.maxAmountGiven,
      form.isExactDate,
      form.expiredDate && (form.expiryDay <= 0) ? dayjs(form.expiredDate, 'YYYY-MM-DD HH:mm:ss').endOf('days').format() : "",
      form.expiryDay,
      // form.limitPerDay,
      // form.limitPerUser,
      form.budgetSource?.value === 'OTHERS'
        ? `OTHERS ${form.budgetSourceOther}`
        : String(form.budgetSource?.value),
      form.triggerBy?.value !== TriggerType.ON_SCHEDULE
        ? dayjs(
            `${form.startDate} ${form.startTime}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format()
        : undefined,
      form.triggerBy?.value !== TriggerType.ON_SCHEDULE
        ? dayjs(
            `${form.endDate} ${form.endTime}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format()
        : undefined,
      adminEmail,
      form.membershipLevel,
      form.budgetAmount,
      form.extraRulesActive,
      form.totalAmountTransaction,
      form.totalAmountTransactionTime,
      form.totalNumberTransaction,
      form.totalNumberTransactionTime,
      form.logicType,
      form.statusType,
      form.shipmentType,
      form.productType,
      form.minimumAmount,
      form.pointValue,
      form.pointValueAmountForInviter,
      form.pointValueAmountForInviterDisburseTo,
      form.pointValueAmountForInviterApprovalType,
      form.pointValueAmountForInviterDisburseType,
      form.pointValueAmountForInviterDisburseTypeShipmentStatus,
      form.pointValueAmountForInviterDisburseTypeSpecificDate,
      form.pointValueAmountForInvitee,
      form.pointValueAmountForInviteeDisburseTo,
      form.pointValueAmountForInviteeApprovalType,
      form.pointValueAmountForInviteeDisburseType,
      form.pointValueAmountForInviteeDisburseTypeShipmentStatus,
      form.pointValueAmountForInviteeDisburseTypeSpecificDate,
      form.pointValueAmountForGeneral,
      form.pointValueAmountForGeneralDisburseTo,
      form.pointValueAmountForGeneralApprovalType,
      form.pointValueAmountForGeneralDisburseType,
      form.pointValueAmountForGeneralDisburseTypeShipmentStatus,
      form.pointValueAmountForGeneralDisburseTypeSpecificDate,
      form.pointValuePercentageForInviter,
      form.pointValuePercentageForInviterMax,
      form.pointValuePercentageForInviterDisburseTo,
      form.pointValuePercentageForInviterApprovalType,
      form.pointValuePercentageForInviterDisburseType,
      form.pointValuePercentageForInviterDisburseTypeShipmentStatus,
      form.pointValuePercentageForInviterDisburseTypeSpecificDate,
      form.pointValuePercentageForInvitee,
      form.pointValuePercentageForInviteeMax,
      form.pointValuePercentageForInviteeDisburseTo,
      form.pointValuePercentageForInviteeApprovalType,
      form.pointValuePercentageForInviteeDisburseType,
      form.pointValuePercentageForInviteeDisburseTypeShipmentStatus,
      form.pointValuePercentageForInviteeDisburseTypeSpecificDate,
      form.pointValuePercentageForGeneral,
      form.pointValuePercentageForGeneralMax,
      form.pointValuePercentageForGeneralDisburseTo,
      form.pointValuePercentageForGeneralApprovalType,
      form.pointValuePercentageForGeneralDisburseType,
      form.pointValuePercentageForGeneralDisburseTypeShipmentStatus,
      form.pointValuePercentageForGeneralDisburseTypeSpecificDate,
      form.pointDisburseType,
      form.shipmentStatus,
      form.specificDate,
      form.pointDisburseLimitPerProgram,
      form.totalNumberDisbursePerProgram,
      form.totalAmountDisbursePerProgram,
      form.pointDisburseLimitPerUser,
      form.totalNumberDisbursePerUser,
      form.totalAmountDisbursePerUser,
      form.pointPurposeState,
      form.deviceIdLimitCustomer,
    )

    this.setCreateProgramPayload(payload)

    if (form.programId) {
      this.presenter
        .update(form.programId, this.programPayload)
        .then(() => {
          EventBus.$emit(EventBusConstants.SAVE_PROGRAM_SUCCESS, {})
        })
        .catch(error => {
          Vue.notify({
            title: 'Update Program Failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        })
        .finally(() => {
          this.setLoading(false)
        })
    } else {
      this.presenter
        .create(this.programPayload)
        .then(() => {
          EventBus.$emit(EventBusConstants.SAVE_PROGRAM_SUCCESS, {})
        })
        .catch(error => {
          Vue.notify({
            title: 'Create Program Failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        })
        .finally(() => {
          this.setLoading(false)
        })
    }
  }

  @Action
  public cancelProgram(id: string) {
    this.setLoading(true)
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    this.presenter
      .delete(id, new DeleteProgramaticPointRequest(adminEmail))
      .then(() => {
        this.setStatusCancelledProgram(EventBusConstants.CANCEL_PROGRAM_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Cancel Program Error',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action
  public getPaymentMethodList() {
    this.setPaymentMethodLoading(true)

    this.presenter
      .getPaymentMethodList()
      .then(resp => this.setPaymentMethods(resp))
      .catch(error => {
        Vue.notify({
          title: 'Fetch Payment Method Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setPaymentMethodLoading(false)
      })
  }

  @Action
  public getMembershipLevelList() {
    this.setMembershipLevelLoading(true)

    this.presenter
      .getMembershipLevelList()
      .then(resp => this.setMembershipLevel(resp))
      .catch(error => {
        Vue.notify({
          title: 'Fetch Payment Method Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setMembershipLevelLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAllProgrammaticPointAndSaldo(params: Record<string, unknown>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllProgrammaticPointAndSaldo(formattedParams)
      .then(resp => {
        this.setAllProgrammaticData(resp)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch All Programmatic Data Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAllProgrammaticPointAndSaldoInviteInviter(params: Record<string, unknown>) {
    this.setLoadingInviteeInviter(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
    .getAllProgrammaticPointAndSaldoInviteeInviter(formattedParams)
    .then(resp => {
      this.setAllProgrammaticInviteeInviterData(resp)
    })
    .catch(error => {
      Vue.notify({
        title: 'Fetch All Programmatic Data Detail Failed',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
      })
    })
    .finally(() => {
      this.setLoadingInviteeInviter(false)
    })
  }

  @Action({ rawError: true })
  public allProgrammaticPointApproval(payload: { listIDs: number[]; status: string, programmaticEligibleUserId: number }) {
    this.setLoadingSubmitApproval(true)

    this.presenter
    .allProgrammaticPointApproval(payload)
    .then(() => {
      this.setSuccessSubmitApproval(true)
    })
    .catch(error => {
      this.setSuccessSubmitApproval(false)
      Vue.notify({
        title: 'Approval Program Error',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => {
      this.setLoadingSubmitApproval(false)
    })
  }

  @Mutation
  private setPointPurposes(purposes: PointPurpose[]) {
    this.pointPurposes = purposes
  }

  @Mutation
  private setTriggerData(triggers: Trigger[]) {
    this.triggerData = triggers.map(item => ({
      label: item.triggerName,
      value: item.triggerId,
    }))
  }

  @Mutation
  private setProgram(program: Program) {
    this.programDetail = program
  }

  @Mutation
  private setProgramHistory(histories: ProgramHistories) {
    this.paginationHistory = <Pagination>histories.pagination
    this.historyData = <ProgramHistory[]>histories.data
  }

  @Mutation
  private setPrograms(programs: Programs) {
    this.paginationData = <Pagination>programs.pagination
    this.programData = <Program[]>programs.data
  }

  @Mutation
  private setAllProgrammaticData(programs: ProgrammaticParcelPoinAndSaldos) {
    this.paginationProgrammaticParcelPoinAndSaldo = <ProgrammaticParcelPoinAndSaldoPagination>programs.pagination
    this.programmaticParcelPoinAndSaldoData = <ProgrammaticParcelPoinAndSaldo[]>programs.data
  }

  @Mutation
  public resetAllProgrammaticData() {
    this.paginationProgrammaticParcelPoinAndSaldo = new ProgrammaticParcelPoinAndSaldoPagination()
    this.programmaticParcelPoinAndSaldoData = []
  }

  @Mutation
  private setAllProgrammaticInviteeInviterData(programs: ProgrammaticParcelPoinAndSaldoInviteeInviterData) {
    this.paginationProgrammaticPointInviteeInviter = <ProgrammaticParcelPoinAndSaldoInviteeInviterPagination>programs.pagination
    this.programmaticPointInviteeInviterData = <ProgrammaticParcelPoinAndSaldoInviteeInviter[]>programs.data
  }

  @Mutation
  public resetAllProgrammaticInviteeInviterData() {
    this.paginationProgrammaticPointInviteeInviter = new ProgrammaticParcelPoinAndSaldoInviteeInviterPagination()
    this.programmaticPointInviteeInviterData = []
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setLoadingInviteeInviter(bool: boolean) {
    this.isLoadingInviteeInviter = bool
  }

  @Mutation
  public setLoadingSubmitApproval(bool: boolean) {
    this.isLoadingSubmitApproval = bool
  }

  @Mutation
  private setDownloading(bool: boolean) {
    this.isDownloading = bool
  }

  @Mutation
  private setPurposeLoading(bool: boolean) {
    this.isLoadingPurposeOptions = bool
  }

  @Mutation
  private setTriggerLoading(bool: boolean) {
    this.isLoadingTriggers = bool
  }

  @Mutation
  private setRouteLoading(bool: boolean) {
    this.isLoadingRoute = bool
  }

  @Mutation
  private setCreateProgramPayload(payload: CreateProgramaticPointRequest) {
    this.programPayload = payload
  }

  @Mutation
  private setPaymentMethodLoading(bool: boolean) {
    this.isLoadingPaymentMethods = bool
  }

  @Mutation
  private setPaymentMethods(paymentMethods: string[]) {
    this.paymentMethods = paymentMethods
  }

  @Mutation
  private setMembershipLevelLoading(bool: boolean) {
    this.isLoadingMembershipLevel = bool
  }

  @Mutation
  private setMembershipLevel(membershipLevel: { label: string, value: number }[]) {
    this.membershipLevel = membershipLevel
  }

  @Mutation
  private setParcelPointProgram(val: boolean) {
    this.isParcelPointProgram = val
  }

  @Mutation
  public setStatusCancelledProgram(status: string) {
    this.statusCancelledProgram = status
  }

  @Mutation
  public setSuccessSubmitApproval(isSuccess: boolean) {
    this.successSubmitApproval = isSuccess
  }
}

export default getModule(ProgramaticPointController)
