import {
  GroupOption,
  LabelValue,
} from '@/app/ui/components/OptionBox/interfaces'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'

export enum UserType {
  SELECTED = 'SELECTED_USERS',
  PHONE_NUMBER = 'ALL_USER_BY_PHONE_NUMBER',
  MEMBERSHIP = 'ALL_USER_BY_MEMBERSHIP_LEVEL',
  LOGIN = 'ALL_LOGIN_USERS',
  WITH_REFERRAL = 'USER_WITH_REFERRAL_CODE',
  WITHOUT_REFERRAL = 'WITHOUT_REFERRAL_CODE_MAPPED',
}

export enum TriggerType {
  PAID_ALL = 'INVOICE_PAID_ALL',
  SHIPMENT_PAID_BY_CITY = 'SHIPMENT_PAID_BY_CITY',
  REFERRAL_INVITEE = 'REFERRAL_INVITEE',
  REFERRAL_CODE_USED = 'REFERRAL_SOURCE',
  REGISTRATION = 'REGISTRATION',
  ON_SCHEDULE = 'ON_SCHEDULE',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  TRANSACTION_DONE = 'TRANSACTION_DONE',
}

export enum PointValueType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export enum PointValue {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PointDisburseType {
  DIRECTLY_AFTER_TRIGGER = 'DIRECTLY_TRIGGER',
  DIRECTLY_AFTER_FIRST_TRANSACTION = 'DIRECTLY_TRANSACTION',
  SPECIFIC_DATE_EVERY_MONTH = 'SPECIFIC_DATE',
}

export enum PointDisburseTo {
  POINT = 'PARCEL_POINT',
  BALANCE = 'BALANCE',
}

export enum PointApprovalType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export const TARGET_OPTIONS: Array<DropDownLabelValue<UserType>> = [
  { label: 'Selected Users', value: UserType.SELECTED },
  { label: 'All User by Phone Number', value: UserType.PHONE_NUMBER },
  { label: 'Membership Level', value: UserType.MEMBERSHIP },
  { label: 'All Login Users', value: UserType.LOGIN },
  { label: 'User with Referral Code Mapped', value: UserType.WITH_REFERRAL },
  { label: 'User without Referral Code Mapped', value: UserType.WITHOUT_REFERRAL },
]

export const BUDGET_SOURCE: Array<DropDownLabelValue<string>> = [
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Operation', value: 'OPERATION' },
  { label: 'Product', value: 'PRODUCT' },
  { label: 'UX', value: 'UX' },
  { label: 'QA', value: 'QA' },
  { label: 'CS', value: 'CS' },
  { label: 'HRD', value: 'HRD' },
  { label: 'Others', value: 'OTHERS' },
]

export const POINT_VALUE: Array<DropDownLabelValue<string>> = [
  { label: 'Amount', value: PointValue.AMOUNT },
  { label: 'Percentage', value: PointValue.PERCENTAGE },
]

export const POINT_DISBURSE_TYPE: Array<DropDownLabelValue<string>> = [
  { label: 'Directly After Trigger', value: PointDisburseType.DIRECTLY_AFTER_TRIGGER },
  { label: 'Directly After First Transaction', value: PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION },
  { label: 'Specific Date, Every Month', value: PointDisburseType.SPECIFIC_DATE_EVERY_MONTH },
]

export const POINT_DISBURSE_PROGRAM_TIME: Array<DropDownLabelValue<string>> = [
  { label: 'Daily', value: 'DAILY' },
]

export const POINT_DISBURSE_USER_TIME: Array<DropDownLabelValue<string>> = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Monthly', value: 'MONTHLY' },
]

export const POINT_DISBURSE_TO: Array<DropDownLabelValue<string>> = [
  { label: 'Point', value: PointDisburseTo.POINT },
  { label: 'Saldo', value: PointDisburseTo.BALANCE },
]

export const POINT_APPROVAL_TYPE: Array<DropDownLabelValue<string>> = [
  { label: 'Auto Approve', value: PointApprovalType.AUTO },
  { label: 'Manual Approve', value: PointApprovalType.MANUAL },
]

export const TIME_FREQ: Array<DropDownLabelValue<string>> = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
]

export const SHIPMENT_STATUS: Array<DropDownLabelValue<string>> = [
  { label: 'BKD', value: 'BKD' },
  { label: 'POD', value: 'POD' },
]

export const SPECIFIC_DATE: Array<DropDownLabelValue<number>> = Array.from({ length: 31 }, (_x, i) => ({
  label: `${ i + 1 }`,
  value: i + 1
}))

export const INVOICE_SOURCE: GroupOption<string, string>[] = [
  {
    group: 'CA',
    options: [
      { label: 'Pickup Regular', value: 'PICKUP' },
      { label: 'Pickup Favorit', value: 'PICKUP_FAVORITE' },
      { label: 'Dropoff Regular', value: 'DROPOFF' },
      { label: 'Dropoff Favorit', value: 'DROPOFF_FAVORITE' },
      { label: 'Shop', value: 'SHOP' },
    ],
  },
  { label: 'Marketplace', value: 'MARKETPLACE' },
  { label: 'POS', value: 'POS' },
]

export const INVOICE_SOURCE_MEMBERSHIP: GroupOption<string, string>[] = [
  {
    group: 'CA',
    options: [
      { label: 'Pickup Regular', value: 'PICKUP' },
      { label: 'Pickup Favorit', value: 'PICKUP_FAVORITE', disabled: true },
      { label: 'Dropoff Regular', value: 'DROPOFF' },
      { label: 'Dropoff Favorit', value: 'DROPOFF_FAVORITE', disabled: true },
      { label: 'Shop', value: 'SHOP', disabled: true },
    ],
  },
  { label: 'Marketplace', value: 'MARKETPLACE', disabled: true },
  { label: 'POS', value: 'POS', disabled: true },
]

export const MEMBERSHIP_LEVEL: Array<LabelValue<string>> = [
  {
    label: 'Premium',
    value: 'Premium',
  },
  {
    label: 'Regular',
    value: 'Regular',
  },
  {
    label: 'Basic',
    value: 'Basic',
  },
]

const noteReferral =
  'Referral points are eligible for users who has made first transaction and never deleted their account'

export const TRIGGERED_BY_NOTE: Record<string, string> = {
  [TriggerType.REFERRAL_CODE_USED]: noteReferral,
  [TriggerType.REFERRAL_INVITEE]: noteReferral,
  [TriggerType.REGISTRATION]:
    'Registration points are eligible for users who has never deleted their account',
}

export const PARCEL_POINT_NAME = 'Parcel Poin'
